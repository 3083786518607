<template>
  <a-form-item
    v-if="!record.options.hide"
    v-show="record.options.show !== false"
    v-bind="record.formItem"
    :colon="valueForEmpty('colon')"
    :selfUpdate="valueForEmpty('selfUpdate')"
    :label-width="setLabelWidth(record)"
  >
    <template slot="label">
      <span v-html="record.label"></span>
      <a-tooltip class="label-help" placement="topRight" v-if="record.formItem && record.formItem.labelHelp">
        <template #title>
          <span>{{record.formItem.labelHelp}}</span>
        </template>
        <a-icon type="question-circle" />
      </a-tooltip>
    </template>
    <slot :record="record">
      <component
        v-decorator="[record.model, {
          initialValue: record.options.defaultValue,
          rules: record.rules,
          ...record.VDOptions
        }]"
        v-bind="$attrs"
        v-on="$listeners"
        :is="currentView(record)"
        :record="record"
        :style="`width:${record.options.width}`"
      />
    </slot>
  </a-form-item>
</template>

<script>
import axInput from './input'
import axInputNumber from './input-number'

import axSelect from './select'
import axTreeSelect from './tree-select'
import axRadio from './radio'
import axCheckbox from './checkbox'

import axDatePicker from './date-picker/date-picker'
import axMonthPicker from './date-picker/month-picker'
import axWeekPicker from './date-picker/week-picker'
import axYearPicker from './date-picker/year-picker'
import axRangePicker from './date-picker/range-picker'
import axTimePicker from './time-picker'

import axSwitch from './switch'
import axRate from './rate'
import axQuillXm135 from './quill-xm-135/index'
import axAutoComplete from './auto-complete'

export default {
  name: 'AxFormItem',
  props: {
    record: { // 当前控件
      type: Object,
      default: () => ({})
    },
    formConfig: { // 表单设置
      type: Object,
      default: () => ({})
    }
  },
  components: {
    axInput,
    axInputPassword: axInput,
    axInputSearch: axInput,
    axTextarea: axInput,
    axInputNumber,

    axSelect,
    axTreeSelect,
    axRadio,
    axCheckbox,

    axDatePicker,
    axMonthPicker,
    axWeekPicker,
    axYearPicker,
    axRangePicker,
    axTimePicker,

    axSwitch,
    axRate,
    axQuillXm135,
    axAutoComplete
  },
  computed: {
    setLabelWidth (e) { // label的宽度
      return record => {
        if (!record.label || this.formConfig.layout === 'vertical') return false
        // label的宽度
        const labelWidth = this.valueForEmpty('labelWidth')
        this.$nextTick(() => {
          const formItemLabel = e?.$el?.getElementsByClassName('ant-form-item-label')[0]
          if (formItemLabel) formItemLabel.style.setProperty('width', `${labelWidth}px`)
          // 设置label对齐方式
          const labelAlign = this.valueForEmpty('labelAlign') === 'left' ? 'flex-start' : 'flex-end'
          formItemLabel.querySelector('label').style.setProperty('justify-content', labelAlign)
          // label过长省略
          if (this.isEllipsis(record.label, labelWidth)) {
            formItemLabel.classList.add('ellipsis')
            formItemLabel.querySelector('label').title = record.label
          }
        })
      }
    },
    currentView () { // 当前表单控件
      return record => `ax-${record?.type?.split(/(?=[A-Z])/).join('-').toLowerCase() || 'input'}`
    }
  },
  methods: {
    valueForEmpty (propertyKey) {
      return this.$ax.tools.valueForEmpty(this.record.formItem?.[propertyKey], this.formConfig[propertyKey])
    },
    isEllipsis (content, contentWidth) {
      const el = document.createElement('div') // 创建一个临时div
      el.innerHTML = content
      el.style.whiteSpace = 'nowrap' // 不换行
      el.style.position = 'absolute'
      el.style.opacity = 0 // 完全透明
      document.body.appendChild(el)
      const elWidth = el.clientWidth // 获取这个含有content内容的临时div的宽度
      document.body.removeChild(el)
      return elWidth >= contentWidth // 判断这个临时div的宽度是否大于原节点宽度的两倍
    }
  }
}
</script>
