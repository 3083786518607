<template>
  <div :style="{ height: record.options.height + 'px'}">
    <div class="ql-editor">
      <!-- 工具栏 -->
      <div id="toolbar">
        <span class="ql-formats">
          <select class="ql-font"></select>
          <select class="ql-size"></select>
        </span>
          <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
        </span>
          <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>
          <span class="ql-formats">
          <button class="ql-script" value="sub"></button>
          <button class="ql-script" value="super"></button>
        </span>
          <span class="ql-formats">
          <button class="ql-header" value="1"></button>
          <button class="ql-header" value="2"></button>
          <button class="ql-blockquote"></button>
          <button class="ql-code-block"></button>
        </span>
          <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-indent" value="-1"></button>
          <button class="ql-indent" value="+1"></button>
        </span>
          <span class="ql-formats">
          <button class="ql-direction" value="rtl"></button>
          <select class="ql-align"></select>
        </span>
          <span class="ql-formats">
          <button class="ql-link"></button>
          <button class="ql-image"></button>
          <button class="ql-video"></button>
          <button class="ql-formula"></button>
        </span>
          <span class="ql-formats">
          <button class="ql-clean"></button>
        </span>
      </div>
      <!--  内容区域 -->
      <div class="editor" ref="editor"/>
    </div>
    <!-- 秀米 -->
    <modalXiumi :visible.sync="visibleXiumi"/>
    <!-- 135 -->
    <modal135 :visible.sync="visible135"/>
  </div>
</template>

<script>
// 引入原始组件
import * as Quill from 'quill'
// 引入核心样式和主题样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// 引入自定义blot
import blotSelect from './blot'

import formEleMixin from '../form-ele-mixin'

import modalXiumi from './modal-xiumi'
import modal135 from './modal-135'
blotSelect(Quill)

export default {
  name: 'AxQuillXm135',
  mixins: [formEleMixin],
  components: { modalXiumi, modal135 },
  data () {
    return {
      visibleXiumi: false,
      visible135: false,
      selection: {}, // 光标位置
      quill: null,
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar'
        }
      }
    }
  },
  mounted () {
    this.initEditor()
  },
  methods: {
    /**
     * 初始化编辑器
     */
    initEditor () {
      // 获取编辑器的DOM容器
      const editorDom = this.$el.querySelector('.editor')
      // 初始化编辑器
      this.quill = new Quill(editorDom, this.editorOptions)
      // 监听光标的位置
      this.quill.on('selection-change', range => {
        this.selection = this.quill.getSelection()
        if (!range) {
          this.$emit('blur', this.quill)
        } else {
          this.$emit('focus', this.quill)
        }
      })
      // 实现双向绑定
      this.quill.on('text-change', () => {
        this.emitChange()
        this.selection = this.quill.getSelection()
      })
      // 插入内容
      this.firstSetHtml()
      // 粘贴板监听
      this.listenPaste()
      // 编辑器初始化完成
      this.$emit('ready', this.quill)
    },
    /**
     * 更新text-change
     */
    emitChange () {
      // 获取到quill 根dom中的html
      let html = this.$refs.editor.children[0].innerHTML
      const quill = this.quill
      const text = this.quill.getText()
      if (html === '<p><br></p>') html = ''
      // v-model相关
      // this.$emit('input', html, this.record.model)
      this.$emit('change', html, this.record.model, { html, text, quill })
      // 返回quill中文本长度
      // bug注意：这个方法无法计算秀米代码中的文字长度！
      this.$emit('get-content-length', this.quill.getLength())
    },
    /**
     * 回显内容时检查秀米或者135代码
     */
    firstSetHtml () {
      if (this.value) {
        // 判断是否有秀米和或135元素
        if (this.value.indexOf('xiumi.us') > -1 || this.value.indexOf('135editor.com') > -1) {
          const originNode = new DOMParser().parseFromString(this.value, 'text/html').body.childNodes
          this.nodesInQuill(originNode)
        } else {
          // 正常插入
          this.quill.clipboard.dangerouslyPasteHTML(this.value)
        }
      }
    },
    /**
     * 监听粘贴板
     */
    listenPaste () {
      this.quill.root.addEventListener('paste', (e) => {
        const msg = (e.clipboardData || window.clipboardData).getData('text/html') // 获取粘贴板文本
        if (msg) {
          if (msg.indexOf('xiumi.us') > -1 || msg.indexOf('_135editor') > -1) {
            const value = new DOMParser().parseFromString(msg, 'text/html').body.childNodes // 获取nodes
            e.preventDefault() // 阻止复制动作
            e.stopPropagation()// 阻止冒泡
            this.nodesInQuill(value) // 根据不同标签，使用不同的插入方法
          }
        }
      })
    },
    /**
     * 根据node类型分发处理
     * @param originNode
     */
    nodesInQuill (originNode) {
      for (let i = originNode.length - 1; i >= 0; i--) {
        if (originNode[i].localName === 'section') {
          // 秀米/135类型代码，走新blot
          this.setRichText(originNode[i].outerHTML, 0)
        } else {
          // 正常插入
          this.quill.clipboard.dangerouslyPasteHTML(0, originNode[i].outerHTML)
        }
      }
    },
    /**
     *  插入秀米或者135代码
     * @param e
     * @param t
     */
    setRichText (e, t) {
      // 插入位置
      const index = this.selection ? this.selection.index : 0
      // 插入数据方法（位置，blot，数据）
      this.quill.insertEmbed(t || index, 'AppPanelEmbed', e)
      // 插入成功后，不管有没有用到a-modal，设置为false就对了
      this.visibleXiumi = false
      this.visible135 = false
    },
    /**
     * 获取html内容
     * @returns {string}
     */
    getHtml () {
      return this.$refs.editor.children[0].innerHTML
    },
    /**
     * 展示秀米的弹窗
     */
    showXiumi () {
      this.visibleXiumi = true
    },
    /**
     * 展示135的弹窗
     */
    show135 () {
      this.visible135 = true
    }
  }
}
</script>

<style lang="less" scoped>
  .ql-editor{
    display: flex;
    flex-direction: column;
    padding: 0;
    .ql-toolbar{
      flex: none;
    }
    .ql-container{
      flex: auto;
      overflow: auto;
    }
  }
  #custom-button-xiumi,
  #custom-button-135{
    position: relative;
    width: 33px;
    height: 16px;
    margin-top: 4px;
    padding-right: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  #custom-button-xiumi {
    background-image: url('img/xiumi-icon.png');
    &:hover {
      background-image: url('img/xiumi-icon.png');
    }
  }
  #custom-button-135 {
    background-image: url('img/135-icon.png');
    &:hover {
      background-image: url('img/135-icon.png');
    }
  }
</style>
