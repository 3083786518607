<template>
  <a-range-picker
    v-bind="record.options"
    :value="formatValue(value)"
    :value-format="valueFormat"
    :getCalendarContainer="record.options.getCalendarContainer || (triggerNode => triggerNode.parentNode)"
    @change="change"
    @calendarChange="calendarChange"
    @openChange="openChange"
    @panelChange="panelChange"
    @ok="ok"
  />
</template>

<script>
import formEleMixin from '../form-ele-mixin'
import mixin from './mixin'
export default {
  name: 'AxRangePicker',
  mixins: [formEleMixin, mixin],
  data () {
    return {
      dateFormat: 'YYYY-MM-DD'
    }
  },
  methods: {
    /**
     * 日期范围发生变化的回调（选第一个值不会触发，第二个值会触发）
     * @param values
     */
    change (values) {
      const { isValueArray } = this.record.options
      const value = isValueArray ? values : this.formatValue(values, 'toStr')
      this.$emit('change', value, this.record.model)
    },
    /**
     * 待选日期发生变化的回调（选中第一个值，第二个值都会触发）
     * @param values
     */
    calendarChange (values) {
      const { isValueArray } = this.record.options
      const value = isValueArray ? values : this.formatValue(values, 'toStr')
      this.$emit('calendarChange', value, this.record.model)
    },
    /**
     * 点击确定按钮的回调（showtime为true会出现ok按钮）
     * 点击确定的时候date肯定有数据，不需要判空
     * @param values
     */
    ok (values) {
      const { isValueArray } = this.record.options
      const value = isValueArray ? values : this.formatValue(values, 'toStr')
      this.$emit('ok', value, this.record.model)
    }
  }
}
</script>

<!--
未设置属性：
mode
open
-->
