<template>
  <div>
    <a-select
      v-if="['tags', 'multiple'].includes(record.options.mode)"
      v-bind="record.options"
      :value="selectedValue"
      :options="undefined"
      :getPopupContainer="record.options.getPopupContainer || (triggerNode => triggerNode.parentNode)"
      @blur="blur"
      @change="change"
      @deselect="deselect"
      @focus="focus"
      @inputKeydown="inputKeydown"
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      @popupScroll="popupScroll"
      @search="search"
      @select="select"
      @dropdownVisibleChange="dropdownVisibleChange"
    >
      <template v-for="(item, index) in options">
        <!-- 数据源带分组 -->
        <a-select-opt-group
          v-if="record.options.isGroup"
          :key="index"
          :label="item.title"
        >
          <a-select-option
            v-for="(child, idx) in item.children"
            :key="idx"
            :disabled="child.disabled"
            :title="child[labelKey]"
            :value="child[valueKey]"
            :label="child[extraLabelKey]"
            :class="child.class"
          >
            {{child[labelKey]}}
          </a-select-option>
        </a-select-opt-group>

        <!-- 数据源不带分组 -->
        <a-select-option
          v-else
          :key="index"
          :disabled="item.disabled"
          :title="item[labelKey]"
          :value="item[valueKey]"
          :label="item[extraLabelKey]"
          :class="item.class"
        >
          {{item[labelKey]}}
        </a-select-option>
      </template>

      <div
        v-if="['tags', 'multiple'].includes(record.options.mode)"
        slot="dropdownRender"
        slot-scope="menu"
      >
        <v-nodes :vnodes="menu" />
        <template v-if="isSelectAll">
          <a-divider style="margin: 4px 0" />
          <a-checkbox
            v-model="defaultChecked"
            @mousedown.native="e => e.preventDefault()"
            @change="selectAll"
            class="select-all"
          >全选</a-checkbox>
        </template>
      </div>
    </a-select>
    <a-select
      v-else
      v-bind="record.options"
      :value="selectedValue"
      :options="undefined"
      :getPopupContainer="record.options.getPopupContainer || (triggerNode => triggerNode.parentNode)"
      @blur="blur"
      @change="change"
      @deselect="deselect"
      @focus="focus"
      @inputKeydown="inputKeydown"
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      @popupScroll="popupScroll"
      @search="search"
      @select="select"
      @dropdownVisibleChange="dropdownVisibleChange"
    >
      <template v-for="(item, index) in options">
        <!-- 数据源带分组 -->
        <a-select-opt-group
          v-if="record.options.isGroup"
          :key="index"
          :label="item.title"
        >
          <a-select-option
            v-for="(child, idx) in item.children"
            :key="idx"
            :disabled="child.disabled"
            :title="child[labelKey]"
            :value="child[valueKey]"
            :label="child[extraLabelKey]"
            :class="child.class"
          >
            {{child[labelKey]}}
          </a-select-option>
        </a-select-opt-group>

        <!-- 数据源不带分组 -->
        <a-select-option
          v-else
          :key="index"
          :disabled="item.disabled"
          :title="item[labelKey]"
          :value="item[valueKey]"
          :label="item[extraLabelKey]"
          :class="item.class"
        >
          {{item[labelKey]}}
        </a-select-option>
      </template>
    </a-select>
  </div>
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxSelect',
  mixins: [formEleMixin],
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => {
        return ctx.props.vnodes
      }
    }
  },
  data () {
    return {
      defaultChecked: false
    }
  },
  watch: {
    selectedValue: {
      handler (value) {
        const { mode } = this.record.options
        if (['multiple', 'tags'].includes(mode)) {
          if (value) this.defaultChecked = value.length === this.options.length
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    selectedValue () {
      const { mode, labelInValue } = this.record.options
      const { value, valueKey, labelKey } = this
      if (this.isEmpty(value)) return
      if (labelInValue && typeof value !== 'object') {
        const label = this.options.find(option => option[valueKey] === value)?.[labelKey]
        return { label: label, value: value }
      }
      return ['multiple', 'tags'].includes(mode) ? this.formatValue(value) : value
    }
  },
  methods: {
    /**
     * 全选
     * @param e
     */
    selectAll (e) {
      if (e.target.checked) {
        this.$emit('change', this.options.map(option => option[this.valueKey]).join(), this.record.model)
      } else {
        this.$emit('change', '', this.record.model)
      }
    },
    /**
     * 选中 option，或 input 的 value 变化（combobox 模式下）时，调用此函数
     * 要保存label的时候，返回去的值肯定是对象
     * 也不会把label和value分两个字段存，由页面自行处理
     * @param selectedValue
     */
    change (selectedValue) {
      if (Array.isArray(selectedValue)) {
        const { labelInValue, isValueArray } = this.record.options
        const value = labelInValue || isValueArray ? selectedValue : this.formatValue(selectedValue, 'toStr')
        this.$emit('change', value, this.record.model)
      } else {
        this.$emit('change', selectedValue, this.record.model)
      }
    },
    /**
     * 失去焦点的时回调
     * @param value
     */
    blur (value) {
      this.$emit('blur', value, this.record.model)
    },
    /**
     * 取消选中时调用，参数为选中项的 value (或 key) 值，仅在 multiple 或 tags 模式下生效
     * @param value
     */
    deselect (value) {
      this.$emit('deselect', value, this.record.model)
    },
    /**
     * 获得焦点时回调
     */
    focus () {
      this.$emit('focus', this.value, this.record.model)
    },
    /**
     * 键盘按下时回调
     * @param e
     */
    inputKeydown (e) {
      this.$emit('inputKeydown', e, this.record.model)
    },
    /**
     * 鼠标移入时回调
     * @param e
     */
    mouseenter (e) {
      this.$emit('inputKeydown', e, this.record.model)
    },
    /**
     * 鼠标移出时回调
     * @param e
     */
    mouseleave (e) {
      this.$emit('inputKeydown', e, this.record.model)
    },
    /**
     * 下拉列表滚动时的回调
     * @param e
     */
    popupScroll (e) {
      this.$emit('popupScroll', e, this.record.model)
    },
    /**
     * 文本框值变化时回调
     * @param value
     */
    search (value) {
      this.$emit('search', value, this.record.model)
    },
    /**
     * 被选中时调用，参数为选中项的 value (或 key) 值
     * @param value
     */
    select (value) {
      this.$emit('select', value, this.record.model)
    },
    /**
     * 展开下拉菜单的回调
     * @param open
     */
    dropdownVisibleChange (open) {
      this.$emit('dropdownVisibleChange', open, this.record.model)
    }
  }
}
</script>
<!--
未放进去的属性：
defaultValue
-->
<!--
注：
option回显的属性由optionLabelProp控制，默认是option的children
-->

<style lang="less" scoped>
.select-all {
  padding: 6px 12px;
}
</style>
